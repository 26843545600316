/* Custom SCSS/SASS Files */
@import 'variables';
@import 'mixins';
@import 'custom';
/* UIKit */
@import "variables-theme.scss";
@import "mixins-theme.scss";
@import "uikit-theme.scss";

html {
	background-color: #f8f8f8;
}

app-home {
	position: relative;
	z-index: 1;
}

h1 {
	font-size: 1.8rem;
	font-weight: 600;
	color: var(--color-primary);
}

h2 {
	font-size: 1.3rem;
	color: var(--color-primary);
}

h3 {
	color: #b0b0b0;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.8rem;
}

p.headline, .job-profile h1 {
	color: #c0c0c0;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.8rem;
}

.kn-button {
	border: 1px solid var(--color-primary);
	transition: all 0.5s;
	&:hover {
		color: #000;
		border-color: var(--color-primary);
	}
}

.uk-button.kn-call-to-action {
	color: white;
    transition: all .2s linear;
    // background: linear-gradient(to right,var(--color-secondary) 50%,var(--color-primary) 50%);
    background: linear-gradient(to right,rgb(8, 199, 146) 50%,rgb(0, 153, 218) 50%);
    background-size: 205% 100%;
    background-position: right bottom;
	border-color: #003369;
	&:hover {
		background-position: left bottom;
	}
	&[disabled] {
		background-position: right bottom !important;
		opacity: 0.5;
	}
}

.uk-button {
	border-radius: 0;
	background-color: #fff;
}

.uk-modal-dialog .uk-button-primary {
	background-color: #1e87f0;
}

.uk-navbar-container {
	height: 80px;
}

.sb-function {
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: bold;
	color: var(--color-secondary);
	&:hover {
		text-decoration: none;
		color: var(--color-primary);
	}
}

.pointer {
	cursor: pointer;
}

.cdk-overlay-container {
	z-index: 1020;
}

// FOOTER

footer {
	font-weight: 300;
	font-size: 0.8rem;
	background-color: var(--background-default);
	color: #fff;
	.footer-logo {
		background-image: var(--website-footer-log-src);
		background-position: 0 center;
		background-size: auto 50px;
		background-repeat: no-repeat;
		height: 50px;
		min-height: 50px;
		margin-bottom: 50px;
	}
	.uk-section-small {
		padding-bottom: 20px;
	}
	p {
		margin-bottom: 20px !important;
		&.topline {
			font-weight: 700;
		}
	}
	a {
		color: #fff;
	}
	hr {
		margin-top: 40px;
	}
	.legal-company-name ::before {
		content: var(--website-legal-company);
	}
	.icons a {
		margin-left: 20px;
	}
	.legal p {
		line-height: 40px;
	}
}

// CONTENTS

.aboutus {
  .no-margin {
    h2, p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.meetus {
  .teaser-headline {
    position: absolute;
    bottom: 0;
    p {
      color: #fff;
      line-height: 125%;
      margin: 10px 0;
      max-width: 70%;
      font-size: 1.2rem;
      &.name {
        margin-top: 0;
        font-size: 0.9rem;
        margin-bottom: 20px;
      }
    }
  }
}

.goodtoknow {
  dt {
    font-size: 1.2rem;
  }
  dd {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
  }
}
